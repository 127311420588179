<template>
  <component :is="layout">
    <div
      id="successful-payment-page"
      class="w-full d-flex flex-column align-center"
      :class="containerClass"
    >
      <div class="text-h4 text--error title">ВАМ ПОДАРОК</div>
      <div class="text-h5 text-center description">
        Уникальные скидки для Вас в интернет-магазинах наших партнеров
      </div>
      <v-btn href="https://get4click.ru/ext/PTJ735TP" x-large color="primary"
        >Получить подарок</v-btn
      >
    </div>
  </component>
</template>

<script>
import PageLayout from "../../components/Layouts/PageLayout";
import { mapState } from "vuex";
export default {
  name: "SuccessfulPayment",
  components: { PageLayout },
  computed: {
    ...mapState("auth", ["isLoggedIn"]),
    layout() {
      return this.isLoggedIn ? PageLayout : "v-container";
    },
    containerClass() {
      return {
        "-logged-in": this.isLoggedIn,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
#successful-payment-page {
  background: url("../../assets/images/success-payment-background.png")
    no-repeat top center;
  background-size: 65%;
  height: 800px;

  &.-logged-in {
    background-position: center center;
  }

  > .title {
    color: var(--v-error-base);
    margin-top: 50px;
    margin-bottom: 30px;
  }

  > .description {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  #successful-payment-page {
    background-position: center center;
    background-size: 80%;
  }
}
</style>
