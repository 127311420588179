import { render, staticRenderFns } from "./SuccessfulPayment.vue?vue&type=template&id=646eff2c&scoped=true&"
import script from "./SuccessfulPayment.vue?vue&type=script&lang=js&"
export * from "./SuccessfulPayment.vue?vue&type=script&lang=js&"
import style0 from "./SuccessfulPayment.vue?vue&type=style&index=0&id=646eff2c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "646eff2c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
